import './style.css';

import emoji_bomb from './metadata/meme/bomb.png';
import emoji_collision from './metadata/meme/collision.png';
import emoji_eagle from './metadata/meme/eagle.png';
import emoji_fire from './metadata/meme/fire.png';
import emoji_usa from './metadata/meme/flag-united-states.png';
import eagle_with_bomb from './metadata/meme/eagle-with-bomb.gif';
import eagle_without_bomb from './metadata/meme/flying-eagle-left-to-right.gif';
import eagle_bomb_dropping from './metadata/meme/bomb-for-eagle.png';
import birdFlagDance from './metadata/meme/eagle-in-usa-shorts.gif';
import flagWave3 from './metadata/meme/flag-wave-3.webp';
import flagWave6 from './metadata/meme/flag-wave-6.gif';
import oasisOilDesert from './metadata/meme/oasis-oil-desert.png';
import explosion_1 from './metadata/meme/explosion-1.gif';
import intro_wtf_mp3 from './metadata/audio/wtfisakilometer-intro.mp3';
import bomb_explode_mp3 from './metadata/audio/distant-explosion-47562.mp3';

import flagWave1 from './metadata/meme/flag-wave-1.webp';
import flagWave4 from './metadata/meme/flag-wave-4.webp';
import eagleWithGun_gif from './metadata/meme/bald-eagle-arms-with-guns-SECOND-HALF.gif';
import eagleLtoR_gif from './metadata/meme/flying-eagle-left-to-right.gif';
import eagleRtoL_gif from './metadata/meme/flying-eagle-right-to-left.gif';
import flagWeirdEffect_webp from './metadata/meme/flagweirdeffect.webp';
import eagleWithGunHalf_gif from './metadata/meme/bald-eagle-arms-with-guns-HALF.gif';
import eagleThrust_gif from './metadata/meme/eagle-in-usa-shorts.gif';
import oldWhiteGuyScreaming_gif from './metadata/meme/oldWhiteGuyScreaming-optimized.gif';
import oldWhiteGuyScreaming2_gif from './metadata/meme/oldWhiteGuy2-optimized.gif';
import measureHappiness_gif from './metadata/meme/cantmeasurehappiness-ezgif.com-optimize.gif';
import theyhaveoil_gif from './metadata/meme/theyhaveoil-ezgif.com-optimize.gif';


const landingPage = document.getElementById('landingPage');
landingPage.style.display = 'block';
//landingPage.style.display = 'none';

const landingButton = document.createElement('button');
landingButton.id = 'landingBtn'
landingButton.innerHTML = "DEPLOY FREEDOM&nbsp;";

const lb_emoji_eagle = document.createElement('img');
lb_emoji_eagle.src = emoji_eagle;
landingButton.appendChild(lb_emoji_eagle);
const lb_emoji_bomb = document.createElement('img');
lb_emoji_bomb.src = emoji_bomb;
landingButton.appendChild(lb_emoji_bomb);
const lb_emoji_collision = document.createElement('img');
lb_emoji_collision.src = emoji_collision;
landingButton.appendChild(lb_emoji_collision);
const lb_emoji_fire = document.createElement('img');
lb_emoji_fire.src = emoji_fire;
landingButton.appendChild(lb_emoji_fire);
const lb_emoji_usa = document.createElement('img');
lb_emoji_usa.src = emoji_usa;
landingButton.appendChild(lb_emoji_usa);

landingPage.appendChild(landingButton);

const landingBgFlag = document.createElement('img');
landingBgFlag.id = "landingBgFlag";
landingBgFlag.src = flagWave3;
landingPage.appendChild(landingBgFlag);

const eagleDanceLeft = document.createElement('div');
eagleDanceLeft.id = "eagleDanceLeft";
landingPage.appendChild(eagleDanceLeft);
const eagleDanceRight = document.createElement('div');
eagleDanceRight.id = "eagleDanceRight";
landingPage.appendChild(eagleDanceRight);

function eagleDance() {
  const imgHeight = 105.769911504;
  const requiredImages = Math.ceil(window.innerHeight/imgHeight);

  // Left Side
  for (let i = 0; i < requiredImages; i++) {
    const img = document.createElement('img');
    img.src = birdFlagDance;
    eagleDanceLeft.appendChild(img);
  }

  // Right Side
  for (let i = 0; i < requiredImages; i++) {
    const img = document.createElement('img');
    img.src = birdFlagDance;
    eagleDanceRight.appendChild(img);
  }
}

const eagleWithBomb = document.createElement('img');
eagleWithBomb.id = "eagleWithBomb";
eagleWithBomb.src = eagle_with_bomb;
landingPage.appendChild(eagleWithBomb);

const eagleWithoutBomb = document.createElement('img');
eagleWithoutBomb.id = "eagleWithoutBomb";
eagleWithoutBomb.src = eagle_without_bomb;
landingPage.appendChild(eagleWithoutBomb);

const eagleBombDropping = document.createElement('img');
eagleBombDropping.id = "eagleBombDropping";
eagleBombDropping.src = eagle_bomb_dropping;
landingPage.appendChild(eagleBombDropping);

const oilfieldExplosion = document.createElement('img');
oilfieldExplosion.id = "oilfieldExplosion";
oilfieldExplosion.src = explosion_1;
landingPage.appendChild(oilfieldExplosion);

oilfieldExplosion.style.display = 'none';
eagleWithBomb.style.display = 'none';
eagleWithoutBomb.style.display = 'none';
eagleBombDropping.style.display = 'none';

function eagleDropBomb() {
  eagleWithBomb.style.display = 'block';
  eagleWithoutBomb.style.display = 'none';
  eagleBombDropping.style.display = 'none';

  const hideEagleWithBomb = function() {
    eagleWithBomb.style.display = 'none';
  }
  const showEagleWithoutBomb = function() {
    eagleWithoutBomb.style.display = 'block';
  }
  const dropBomb = function() {
    eagleBombDropping.style.display = 'block';
  }
  const hideAllEagles = function() {
    eagleWithBomb.style.display = 'none'; 
    eagleWithoutBomb.style.display = 'none';
  }
  const hideBomb = function() {
    eagleBombDropping.style.display = 'none';
  }
  const showExplosion = function() {
    oilfieldExplosion.style.display = 'block';
  }
  const hideExplosion = function() {
    oilfieldExplosion.style.display = 'none';
  }

  setTimeout(hideEagleWithBomb, 1000);
  setTimeout(dropBomb, 1000);
  setTimeout(showEagleWithoutBomb, 1000);
  setTimeout(hideAllEagles, 3000);
  setTimeout(hideBomb, 4000);
  setTimeout(showExplosion, 4000);
  setTimeout(hideExplosion, 5000);
}

const flagGoldenEagle = document.createElement('img');
flagGoldenEagle.id = "flagGoldenEagle";
flagGoldenEagle.src = flagWave6;
landingPage.appendChild(flagGoldenEagle);
flagGoldenEagle.style.display = 'none';

const oilfield = document.createElement('img');
oilfield.id = "oilfield";
oilfield.src = oasisOilDesert;
landingPage.appendChild(oilfield);


const mainPage = document.getElementById('mainPage');
mainPage.style.display = 'none';
//mainPage.style.display = 'block';

const flagBgMain = document.createElement('img');
flagBgMain.id = "flagBgMain";
flagBgMain.src = flagWave1;
mainPage.appendChild(flagBgMain);

const header1 = document.createElement('h1');
header1.id = "header1";
header1.innerHTML = "WHAT THE FUCK";
mainPage.appendChild(header1);
const header2 = document.createElement('h1');
header2.id = "header2";
header2.innerHTML = "IS A KILOMETER";
mainPage.appendChild(header2);

const socials = document.createElement('div');
socials.id = 'socials';

const telegramBtn = document.createElement('button');
telegramBtn.id = "telegramBtn";
telegramBtn.innerHTML = `Telegram`;
socials.appendChild(telegramBtn);

const twitterBtn = document.createElement('button');
twitterBtn.id = "twitterBtn";
twitterBtn.innerHTML = `Twitter`;
socials.appendChild(twitterBtn);

const chartBtn = document.createElement('button');
chartBtn.id = "chartBtn";
chartBtn.innerHTML = `Chart`;
socials.appendChild(chartBtn);

const buyBtn = document.createElement('button');
buyBtn.id = "buyBtn";
buyBtn.innerHTML = `BUY`;
socials.appendChild(buyBtn);

mainPage.appendChild(socials);

const memeContainer = document.createElement('div');
memeContainer.id = "memeContainer";
mainPage.appendChild(memeContainer);

function memeContainerContent() {
  const memeContainerLeft = document.createElement('div');

  const eagleWithGun = document.createElement('img');
  eagleWithGun.src = eagleWithGun_gif;
  memeContainerLeft.appendChild(eagleWithGun);
  
  const eagleThrust = document.createElement('img');
  eagleThrust.src = eagleThrust_gif;
  memeContainerLeft.appendChild(eagleThrust);
  
  const oldWhiteGuyScreaming2 = document.createElement('img');
  oldWhiteGuyScreaming2.src = oldWhiteGuyScreaming2_gif;
  memeContainerLeft.appendChild(oldWhiteGuyScreaming2);

  const theyhaveoil = document.createElement('img');
  theyhaveoil.src = theyhaveoil_gif;
  memeContainerLeft.appendChild(theyhaveoil);
  
  const memeContainerRight = document.createElement('div');
  
  const flagWeirdEffect = document.createElement('img');
  flagWeirdEffect.src = flagWeirdEffect_webp;
  memeContainerRight.appendChild(flagWeirdEffect);
  
  const oldWhiteGuyScreaming = document.createElement('img');
  oldWhiteGuyScreaming.src = oldWhiteGuyScreaming_gif;
  memeContainerRight.appendChild(oldWhiteGuyScreaming);
  
  const eagleWithGunHalf = document.createElement('img');
  eagleWithGunHalf.src = eagleWithGunHalf_gif;
  memeContainerRight.appendChild(eagleWithGunHalf);
  
  const measureHappiness = document.createElement('img');
  measureHappiness.src = measureHappiness_gif;
  memeContainerRight.appendChild(measureHappiness);
  
  const eagleLtoR = document.createElement('img');
  eagleLtoR.id = 'eagleLtoR';
  eagleLtoR.src = eagleLtoR_gif;
  memeContainer.appendChild(eagleLtoR);
  const eagleRtoL = document.createElement('img');
  eagleRtoL.id = 'eagleRtoL';
  eagleRtoL.src = eagleRtoL_gif;
  memeContainer.appendChild(eagleRtoL);
  
  memeContainer.appendChild(memeContainerLeft);
  memeContainer.appendChild(memeContainerRight);
}


const footer = document.querySelector('footer');

const contract = document.createElement('span');
contract.innerHTML = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
footer.appendChild(contract);

const copyContract = document.createElement('button');
copyContract.innerHTML = "COPY";
footer.appendChild(copyContract);


document.addEventListener('DOMContentLoaded', function() {
  eagleDance(); 

  const landingIntroAudio = new Audio(intro_wtf_mp3);
  landingIntroAudio.loop = false;
  landingIntroAudio.volume = 1.0;

  const bombExplodeAudio = new Audio(bomb_explode_mp3);
  bombExplodeAudio.loop = false;
  bombExplodeAudio.volume = 1.0;

  landingButton.addEventListener('click', function() {
    landingButton.style.display = 'none';
    flagGoldenEagle.style.display = 'block';
    eagleDropBomb(); 

    landingIntroAudio.play();

    const playBombExplodeAudio = function() {
      bombExplodeAudio.play();
    }
    setTimeout(playBombExplodeAudio, 4000);
    
    const revealSite = function() {
      landingPage.style.display = 'none';
      mainPage.style.display = 'block';
    }
    setTimeout(revealSite, 6000);
  });

  memeContainerContent();

  // Hyper Links
  telegramBtn.addEventListener('click', () => window.open("https://t.me/", "_self"));
  twitterBtn.addEventListener('click', () => window.open("https://x.com/", "_self"));
  chartBtn.addEventListener('click', () => window.open("https://dexscreener.com/solana/", "_blank"));
  buyBtn.addEventListener('click', () => window.open("https://pump.fun/", "_blank"));

  // Copy Contract Logic
  const textToCopy = contract.innerHTML;
  copyContract.addEventListener('click', async() => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch(err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });
});